import { Link } from "gatsby-plugin-intl"
import React from "react"
import "./footer.scss"

interface IndexCoverFooterProps {
  onClickModeTrigger: () => void
  onClickPlayTrigger: () => void
  isPlaying: boolean
}

export const IndexCoverFooter = (props: IndexCoverFooterProps) => {
  return (
    <footer className="footer-home footer-cover flex items-center justify-between w-full px-6 z-1">
      <Link to="/radio">
        <button className="mode-button PPG-Bold">RADIO</button>
      </Link>
      <button
        className="mode-button PPG-Bold"
        onClick={props.onClickModeTrigger}
      >
        GRID
      </button>
    </footer>
  )
}
