import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useRef } from "react"
import { FilterButton } from "./filter.button"
import * as R from "ramda"
import { useIntl } from "gatsby-plugin-intl"
import { useDebounce } from "react-use"
import "./list.scss"

export const ListFilters = ({ onChange }: any) => {
  const [currentFilterType, setCurrentFilterType] = React.useState<
    string | undefined
  >()
  const stickyRef = useRef(null)
  const [filters, setFilters] = React.useState<{ [key: string]: string[] }>({})
  const [currentSort, setCurrentSort] = React.useState<string>()
  const [searchText, setSearchText] = React.useState<string>("")
  const [debouncedSearch, setDebouncedSearch] = React.useState("")

  const [, cancel] = useDebounce(
    () => {
      setDebouncedSearch(searchText)
    },
    200,
    [searchText]
  )

  useEffect(() => {
    if (
      Object.values(filters).length > 0 ||
      !!currentSort ||
      debouncedSearch.length > -1
    ) {
      onChange({
        filters,
        sort: currentSort,
        search: debouncedSearch,
      })
    }
  }, [JSON.stringify(filters), currentSort, debouncedSearch])
  const intl = useIntl()
  const data = useStaticQuery<any>(graphql`
    {
      allStrapiLabel(sort: {order: ASC, fields: index}) {
        nodes {
          name
        }
      }
      allStrapiGenre {
        distinct(field: name)
      }
    }
  `)
  const handleFilterChange = (label: string, data: any) => {
    const currentFilter = filters[label] || []
    if (!currentFilter.includes(data)) {
      setFilters({
        ...filters,
        [label]: [...currentFilter, data],
      })
    } else {
      setFilters({
        ...filters,
        [label]: R.without([data], currentFilter),
      })
    }
  }

  const handleSortChange = (data: any) => {
    if (currentSort === data) {
      setCurrentSort(undefined)
    } else {
      setCurrentSort(data)
    }
  }
  return (
    <div
      className="flex items-center justify-start sm:justify-center gap-4 release-filter"
      style={{
        zIndex: 5,
      }}
    >
      <FilterButton
        label="Label"
        isOpen={currentFilterType === "Label"}
        value={filters["Label"]}
        onClick={(label: string) =>
          setTimeout(() => setCurrentFilterType(label), 5)
        }
        onClose={() => setCurrentFilterType(undefined)}
        onChange={(v: string) => handleFilterChange("Label", v)}
        data={R.pathOr([], ["allStrapiLabel", "nodes"], data).map(i => ({
          label: i.name,
          value: i.name,
        }))}
      />
      <FilterButton
        label="Type"
        isOpen={currentFilterType === "Type"}
        value={filters["Type"]}
        onClick={(label: string) =>
          setTimeout(() => setCurrentFilterType(label), 5)
        }
        onClose={() => setCurrentFilterType(undefined)}
        onChange={(v: string) => handleFilterChange("Type", v)}
        data={[
          {
            value: "Album",
            label: intl.formatMessage({ id: "release_type.album" }),
          },
          {
            value: "EP",
            label: intl.formatMessage({ id: "release_type.ep" }),
          },
          {
            value: "Single",
            label: intl.formatMessage({ id: "release_type.single" }),
          },
        ]}
      />
      <FilterButton
        label="Genre"
        value={filters["Genre"]}
        isOpen={currentFilterType === "Genre"}
        onClick={(label: string) =>
          setTimeout(() => setCurrentFilterType(label), 5)
        }
        onClose={() => setCurrentFilterType(undefined)}
        onChange={(v: string) => handleFilterChange("Genre", v)}
        data={R.pathOr([], ["allStrapiGenre", "distinct"], data).map(i => ({
          label: i,
          value: i,
        }))}
      />
      <FilterButton
        label="Sort"
        value={currentSort}
        isOpen={currentFilterType === "Sort"}
        onClick={(label: string) =>
          setTimeout(() => setCurrentFilterType(label), 5)
        }
        onClose={() => setCurrentFilterType(undefined)}
        onChange={(v: string) => {
          handleSortChange(v)
          setCurrentFilterType(undefined)
        }}
        data={[
          {
            value: "newest",
            label: intl.formatMessage({ id: "sort.newest" }),
          },
          {
            value: "oldest",
            label: intl.formatMessage({ id: "sort.oldest" }),
          },
          {
            value: "alphabetical",
            label: intl.formatMessage({ id: "sort.alphabetical" }),
          },
        ]}
      />
      <div
        className="
        flex items-center bg-black border border-white rounded-full px-4 py-1 flex-shrink-0
        gap-2
      "
      >
        <img src={require("../../images/search.svg").default} />
        <input
          className="outline-none h-6 bg-black text-white search-input ABC-Mono"
          placeholder={intl.formatMessage({ id: "search" })}
          value={searchText}
          onChange={(e: any) => setSearchText(e.target.value)}
        />
      </div>
    </div>
  )
}
