import React, { useState } from "react"
import { Popover } from "react-tiny-popover"
import * as R from "ramda"

export const FilterButton = ({
  isOpen,
  label,
  data,
  value,
  onChange,
  onClick,
  onClose,
}: any) => {
  const hasValue = () => {
    if (Array.isArray(value)) {
      return value.length > 0
    } else {
      return !!value
    }
  }
  // render data as a list, if value equals the data value, then add a class 'selected'
  const renderContent = () => {
    const options = data.map((item: any) => {
      let selected = false
      //if value is an array, then check if the item is in the array
      if (Array.isArray(value)) {
        selected = R.contains(item.value, value)
      } else {
        selected = item.value === value
      }

      return (
        <div
          key={item.value}
          className={`${
            selected ? "selected" : ""
          } text-base flex items-center justify-start gap-2 cursor-pointer`}
          onClick={() => onChange(item.value)}
        >
          <span
            className={`
          rounded-full border border-black 
          ${selected ? "bg-black" : ""}
          `}
            style={{ width: 14, height: 14 }}
          />
          <span>{item.label}</span>
        </div>
      )
    })

    return (
      <div
        className={`filter-button-content bg-white px-4 py-5 border border-black grid ${
          label === "Genre"
            ? "grid-cols-2 gap-x-4 gap-y-2"
            : "grid-cols-1 gap-2"
        } ABC-Mono`}
      >
        {options}
      </div>
    )
  }

  return (
    <Popover
      isOpen={isOpen}
      positions={["top"]}
      content={() => renderContent()}
      onClickOutside={() => onClose(label)}
      padding={12}
    >
      <button
        onClick={() => {
          isOpen ? onClose(label) : onClick(label)
        }}
        className={`
          flex items-center px-4 py-1 justify-center rounded-full md:hover:bg-white md:hover:text-black ABC-Mono
          ${
            isOpen || hasValue()
              ? "bg-white text-black border border-black "
              : "border border-white bg-black text-white"
          }
        `}
      >
        {label}
      </button>
    </Popover>
  )
}
