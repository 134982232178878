import * as React from "react"
import "../styles/theme.scss"
import "./index.scss"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import { useEffect, useState } from "react"
import * as R from "ramda"
import { GraphQLItemResponse, GraphQLListResponse } from "../types"
import { IRelease } from "../types/models/release"
import { IndexCoverFooter } from "../components/index/footer.cover"
import { IndexGridFooter } from "../components/index/footer.grid"
import { IndexGridList } from "../components/index/list.grid"
import { IndexCoverList } from "../components/index/list.cover"
import { PlayerContext } from "../components/audioPlayerWrapper"
import { useMobile } from "../components/useBreakPoint"

function shuffle(array: any[]) {
  const na = [...array]
  let currentIndex = na.length,
    randomIndex

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[na[currentIndex], na[randomIndex]] = [na[randomIndex], na[currentIndex]]
  }

  return na
}

const ReleasePage = ({ data }: any) => {
  const intl = useIntl()
  const [filters, setFilters] = useState<any>()
  const [isMobile] = useMobile()
  const [displayType, setDisplayType] = useState<"grid" | "cover">(
    isMobile ? "grid" : "cover"
  )

  const listData = R.pipe<
    GraphQLListResponse<IRelease>,
    GraphQLItemResponse<IRelease>[],
    GraphQLItemResponse<IRelease>[]
  >(
    R.pathOr([], ["allStrapiRelease", "edges"]),
    R.filter(i => R.path(["node", "locale"])(i) === intl.locale)
  )(data)

  const featuredData = shuffle([...R.take(50)(shuffle(listData))])
  const consumer = React.useContext(PlayerContext)
  useEffect(() => {
    if (displayType === "cover") {
      document.body.classList.add("index-cover")
      consumer.setHidePlayer(true)
    } else {
      document.body.classList.remove("index-cover")
      if (consumer.playlist.length > 0) {
        consumer.setHidePlayer(false)
      }
    }

    return () => {
      document.body.classList.remove("index-cover")
      if (consumer.playlist.length > 0) {
        consumer.setHidePlayer(false)
      }
    }
  }, [displayType])

  return (
    <>
      <Seo
        title={"Releases"}
        meta={[
          {
            name: "homepage_mode",
            content: displayType,
          },
        ]}
      />
      <main className={`release-index-container bg-white flex-1 w-full`}>
        {displayType === "cover" ? (
          <IndexCoverList data={featuredData} />
        ) : (
          <IndexGridList filters={filters} data={listData} />
        )}
      </main>
      {displayType === "cover" ? (
        <IndexCoverFooter
          isPlaying={true}
          onClickModeTrigger={() => setDisplayType("grid")}
          onClickPlayTrigger={() => {}}
        />
      ) : (
        <IndexGridFooter
          onClickModeTrigger={() => setDisplayType("cover")}
          onChangeFilters={filters => setFilters(filters)}
        />
      )}
    </>
  )
}

export default ReleasePage

export const query = graphql`
  {
    allStrapiRelease(sort: { order: DESC, fields: published_at }) {
      edges {
        node {
          name
          locale
          slug
          serial_number
          release_type
          published_at
          created_at
          strapiId
          artists {
            name
            id
            slug
          }
          labels {
            name
          }
          songs {
            name
            id
            track {
              url
            }
          }
          updated_at
          cover {
            url
          }
          genres {
            name
          }
        }
      }
    }
  }
`
