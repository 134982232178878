import React, { useEffect } from "react"
import { IRelease } from "../../types/models/release"
import ReleaseItem from "../releaseItem"
import * as R from "ramda"
import "./list.scss"
import { ListFilters } from "./list.filters"
import { GraphQLItemResponse } from "../../types"

interface IndexGridListProps {
  data: GraphQLItemResponse<IRelease>[]
  filters: any
}
export const IndexGridList = (props: IndexGridListProps) => {
  const [data, setData] = React.useState<GraphQLItemResponse<IRelease>[]>(
    props.data
  )

  useEffect(() => {
    const filters = R.pathOr({}, ["filters", "filters"])(props) as any
    const sort = R.pathOr("", ["filters", "sort"])(props) as any
    const search = R.pathOr("", ["filters", "search"])(props) as any

    let _data: GraphQLItemResponse<IRelease>[] = R.pathOr<
      GraphQLItemResponse<IRelease>[]
    >(
      [],
      ["data"]
    )(props)
    if (filters["Label"] && filters["Label"].length) {
      _data = _data.filter(i => {
        const labelsInData = i.node?.labels?.map(i => i.name) || []
        return R.intersection(filters["Label"], labelsInData).length > 0
      })
    }
    if (filters["Genre"] && filters["Genre"].length) {
      _data = _data.filter(i => {
        const genresInData = i.node?.genres?.map(i => i.name) || []
        return R.intersection(filters["Genre"], genresInData).length > 0
      })
    }
    // release_type
    if (filters["Type"] && filters["Type"].length) {
      _data = _data.filter(i => {
        return filters["Type"].includes(i.node?.release_type as string)
      })
    }
    // sort item's node by sort,
    if (sort) {
      _data = R.sortWith<GraphQLItemResponse<IRelease>>([
        (a, b) => {
          if (sort === "newest") {
            return (a.node?.published_at || 0) < (b.node?.published_at || 0)
              ? 1
              : -1
          } else if (sort === "oldest") {
            return (a.node?.published_at || 0) > (b.node?.published_at || 0)
              ? 1
              : -1
          } else {
            return (a.node?.name?.toLowerCase() as string) >
              (b.node?.name?.toLowerCase() as string)
              ? 1
              : -1
          }
        },
      ])(_data)
    }
    if (!!search) {
      _data = R.pipe<
        GraphQLItemResponse<IRelease>[],
        GraphQLItemResponse<IRelease>[]
      >(
        R.filter((i: GraphQLItemResponse<IRelease>) => {
          const _search = search?.toLowerCase()
          return (
            !!(i.node?.name?.toLowerCase().indexOf(_search) > -1) ||
            !!(i.node?.description?.toLowerCase().indexOf(_search) > -1) ||
            !!i.node?.artists?.some(
              i => i.name?.toLowerCase().indexOf(_search) > -1
            )
          )
        })
      )(_data)
    }
    setData(_data)
  }, [JSON.stringify(props.filters)])

  return (
    <>
      <article
        className={`grid index-grid-list  ${
          data.length < 5 ? "dense-list" : ""
        }`}
      >
        {data.map(i => {
          return (
            <section
              key={R.path<number>(["node", "strapiId"])(i)}
              className={`flex items-center md:items-start justify-center index-grid-item`}
            >
              <ReleaseItem data={R.path<IRelease>(["node"])(i)} />
            </section>
          )
        })}
      </article>
    </>
  )
}
