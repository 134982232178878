import { Link } from "gatsby-plugin-intl"
import React from "react"
import { ListFilters } from "./list.filters"

interface RelaseFilters {
  label?: string[]
  type?: string[]
  genre?: string[]
  sort?: string[]
  searchText?: string
}
interface IndexGridFooterProps {
  onClickModeTrigger: () => void
  onChangeFilters: (filters: RelaseFilters) => void
}

// Home grid mode footer.
export const IndexGridFooter = (props: IndexGridFooterProps) => {
  return (
    <footer className="footer-home footer-grid w-full sticky bottom-0 right-0 md:px-6 flex items-center jusitfy-center md:justify-between PPG-Bold">
      <Link to="/radio">
        <button className="mode-button hidden md:block">RADIO</button>
      </Link>
      <div>
        <ListFilters onChange={props.onChangeFilters} />
      </div>
      <button
        className="mode-button hidden md:block"
        onClick={props.onClickModeTrigger}
      >
        COVER
      </button>
    </footer>
  )
}
