import React, { useEffect } from "react"
import { GraphQLItemResponse } from "../../types"
import { IArtist, IRelease } from "../../types/models"
import * as R from "ramda"
import "./list.scss"
import { Link } from "gatsby-plugin-intl"
import { useDebouncedCallback } from "use-debounce"
import Img from "gatsby-image"

interface IndexCoverListProps {
  data: GraphQLItemResponse<IRelease>[]
}

let currentMouseTargetTag: any = null
const mouseMoveHandler = (e: any) => {
  currentMouseTargetTag = e?.target?.tagName
}
export const IndexCoverList = ({ data }: IndexCoverListProps) => {
  const [currentHover, setCurrentHover] = React.useState<number[]>([])
  const items: Partial<IRelease>[] = (data || []).map(item => item.node)
  const debounced = useDebouncedCallback(
    // function
    idx => {
      if (currentHover.includes(idx) || currentMouseTargetTag !== "IMG") return
      setCurrentHover([...currentHover, idx])
    },
    // delay in ms
    100
  )

  useEffect(() => {
    document.addEventListener("mousemove", mouseMoveHandler, true)

    return () => {
      document.removeEventListener("mousemove", mouseMoveHandler, true)
    }
  }, [1])

  useEffect(() => {
    setTimeout(() => {
      if (currentHover.length > 1) {
        setCurrentHover([currentHover[1]])
      }
    }, 100)
  }, [currentHover.join(",")])

  return (
    <article className="index-cover-list">
      <ul onMouseLeave={() => setCurrentHover([])}>
        {items.map((i, idx: number) => {
          const artistNames = R.pipe(
            R.pathOr([], ["artists"]),
            R.map((artist: IArtist) => (
              <Link
                key={artist.id}
                to={`/artists/${artist.slug}`}
                className="artist-link"
              >
                {artist.name}
              </Link>
            )),
            R.intersperse(<span> / </span>)
          )(i)

          return (
            <li
              key={R.path<number>(["strapiId"])(i)}
              onMouseEnter={() => {
                debounced(idx)
              }}
              className={`${currentHover.includes(idx) ? "open" : "normal"}`}
            >
              <Link to={`/releases/${i.slug}`}>
                <Img
                  fixed={{
                    src: `${R.pathOr("", ["cover", "url"])(
                      i
                    )}?x-oss-process=style/index_cover`,
                    srcSet: `${R.pathOr("", ["cover", "url"])(
                      i
                    )}?x-oss-process=style/index_cover`,
                    srcWebp: `${R.pathOr("", ["cover", "url"])(
                      i
                    )}?x-oss-process=style/index_cover`,
                    width: "100%",
                    height: "100%",
                  }}
                  alt={i.name}
                  title={i.slug}
                  backgroundColor="#fff"
                />
              </Link>
              <div className={`cover-intro ${idx < 10 ? "intro-right" : ""}`}>
                <h2 className="ABC-Bold">
                  <Link to={`/releases/${i.slug}`}>{i.name}</Link>
                </h2>
                <p className="ABC">{artistNames}</p>
              </div>
            </li>
          )
        })}
      </ul>
    </article>
  )
}
